import React, { useContext, useState } from "react";
import useHttp from "../../hooks/use-http";

import CartContext from "../../store/cart-context";
import Modal from "../UI/Modal";
import CartItem from "./CartItem";
import Checkout from "./Checkout";

import styles from "./Cart.module.css";

const Cart = (props) => {
  const { isLoading, error, sendRequest: sendOrder } = useHttp(() => {});

  const [formShown, setFormShown] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const cartCtx = useContext(CartContext);
  const hasItem = cartCtx.items.length > 0;

  const displayTotalAmount = cartCtx.totalAmount.toFixed(2);

  const removeItemHandler = (id) => {
    cartCtx.removeItem(id, 1);
  };

  const addItemHandler = (item) => {
    cartCtx.addItem({
      ...item,
      amount: 1,
    });
  };

  const orderButtonClickHandler = () => {
    setFormShown(true);
  };

  const confirmFormHandler = (userData) => {
    sendOrder({
      url: "https://react-http-555d5-default-rtdb.firebaseio.com/Orders.json",
      object: {
        method: "POST",
        body: JSON.stringify({
          user: userData,
          orderedItems: cartCtx.items,
        }),
        Headers: {
          "Content-Type": "application/json",
        },
      },
    });

    for (const item of cartCtx.items) {
      cartCtx.removeItem(item.id, item.amount);
    }

    if (!error && !isLoading) {
      setOrderConfirmed(true);
    }

    setFormShown(false);
  };

  const modalAction = (
    <div className={styles.actions}>
      <button className={styles["button--alt"]} onClick={props.onHideCart}>
        Close
      </button>
      {hasItem && (
        <button className={styles.button} onClick={orderButtonClickHandler}>
          Order
        </button>
      )}
    </div>
  );

  const cartItem = (
    <ul className={styles["cart-items"]}>
      {cartCtx.items.map((item) => (
        <CartItem
          key={item.id}
          id={item.id}
          name={item.name}
          price={item.price}
          amount={item.amount}
          onRemoveItem={removeItemHandler}
          onAddItem={addItemHandler}
        />
      ))}
    </ul>
  );

  let modalContent = (
    <React.Fragment>
      {cartItem}
      <div className={styles.total}>
        <span>Total Amount</span>
        <span>{displayTotalAmount}</span>
      </div>
    </React.Fragment>
  );

  if (isLoading) {
    modalContent = <p>Loading...</p>;
  } else if (!isLoading && error) {
    modalContent = <p>ops,...some things went wrong</p>;
  } else if (orderConfirmed) {
    modalContent = (
      <p>Thank you for ordering with us,...
        <br/>
        order Confirmed Successfully</p>
    );
  }
  return (
    <Modal onClick={props.onHideCart}>
      {modalContent}
      {formShown && (
        <Checkout onConfirm={confirmFormHandler} onCancel={props.onHideCart} />
      )}
      {!formShown && modalAction}
    </Modal>
  );
};

export default Cart;
