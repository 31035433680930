import { useEffect, useState, useCallback } from "react";
import useHttp from "../../hooks/use-http";

import Card from "../UI/Card";
import MealItem from "./MealItem/MealItem";

import styles from "./AvailableMeals.module.css";

// const DUMMY_MEALS = [
//   {
//     id: "m1",
//     name: "Sushi",
//     description: "Finest fish and veggies",
//     price: 22.99,
//     max: 10,
//   },
//   {
//     id: "m2",
//     name: "Schnitzel",
//     description: "A german specialty!",
//     price: 16.5,
//     max: 5,
//   },
//   {
//     id: "m3",
//     name: "Barbecue Burger",
//     description: "American, raw, meaty",
//     price: 12.99,
//     max: 7,
//   },
//   {
//     id: "m4",
//     name: "Green Bowl",
//     description: "Healthy...and green...",
//     price: 18.99,
//     max: 8,
//   },
// ];

const AvailableMeals = () => {
  const [meals, setMeals] = useState([])
  
  const transformData = useCallback ((dataOb) => {
    let loadedMeals = []
    for (const data in dataOb) {
      loadedMeals.push({
        id: data,
        name: dataOb[data].name,
        description: dataOb[data].description,
        price: dataOb[data].price,
        max: dataOb[data].max,
      });
    }

    setMeals(loadedMeals)
  },[]);

  
  const { isLoading, error, sendRequest: fetchMeals } = useHttp(transformData);
  
  useEffect(() => {
    fetchMeals({
      url: "https://react-http-555d5-default-rtdb.firebaseio.com/Meals.json",
      object: {},
    });
  }, [fetchMeals]);
  
  let content = <p>Found No Meals.</p>;

  if (meals.length > 0) {
    content = (
      <ul>
        {meals.map((meal) => (
          <MealItem
            key={meal.id}
            id={meal.id}
            name={meal.name}
            description={meal.description}
            price={meal.price}
            maxItem={meal.max}
          />
        ))}
      </ul>
    );
  }

  if ( isLoading ) {
    content = <p>meals are loading...</p>
  }

  if ( error ) {
    content = <p style={{color: 'red','textAlign': 'center', 'fontSize': '20px'}}>{error}</p>
  }

  return (
    <section className={styles.meals}>
      <Card>
        {content}
        {/* <ul>{mealsList}</ul> */}
      </Card>
    </section>
  );
};

export default AvailableMeals;
