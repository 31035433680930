import { useCallback, useState } from "react";

const useHttp = (requestFunction) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (requestConfig) => {
    setIsLoading(true);

    try {
      const response = await fetch(requestConfig.url, requestConfig.object);

      if ( !response.ok ) {
        throw new Error(`${response.status} Error Code Detected`)
      }

      const data = await response.json();

      // console.log(data)

      requestFunction(data)
    } catch (err) {
      setError(err.message || 'something went wrong');
    }
    setIsLoading(false)
  }, [requestFunction]);

  return {
    isLoading,
    error,
    sendRequest
  }
};

export default useHttp;
