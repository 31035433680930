import { useReducer } from "react";
import CartContext from "./cart-context";

const defaultCartState = {
  items: [],
  totalAmount: 0,
};

const cartReducer = (state, action) => {
  if (action.type === "ADD") {
    const UpdatedTotalAmount =
      state.totalAmount + action.item.amount * action.item.price;
    let updatedItems;

    const existingItemIndex = state.items.findIndex((item) => {
      return item.id === action.item.id;
    });

    const existingItem = state.items[existingItemIndex];

    if (existingItemIndex !== -1) {
      const updatedExisitingItem = {
        ...existingItem,
        amount: existingItem.amount + action.item.amount,
      };
      updatedItems = [...state.items];
      updatedItems[existingItemIndex] = updatedExisitingItem;
    } else {
      updatedItems = state.items.concat(action.item);
    }

    return {
      items: updatedItems,
      totalAmount: UpdatedTotalAmount,
    };
  } else if (action.type === "REMOVE") {
    const existingItemIndex = state.items.findIndex(item => item.id === action.id)
    const existingItem = state.items[existingItemIndex]

    const updatedTotalAmount = state.totalAmount - existingItem.price * action.amount
    let updatedItems;

    if ( existingItem.amount === action.amount ) {
        updatedItems = state.items.filter( item => item.id !== action.id)
    }
    else {
        const updatedExisitingItem = {
            ...existingItem,
            amount: existingItem.amount - action.amount
        }

        updatedItems = [...state.items]
        updatedItems[existingItemIndex] = updatedExisitingItem
    }
  
      return {
        items: updatedItems,
        totalAmount: updatedTotalAmount
      };

  } else {
    return defaultCartState;
  }
};

const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );

  const addItemToCartHandler = (item) => {
    dispatchCartAction({ type: "ADD", item: item });
  };

  const removeItemFromCartHandler = (id, amount) => {
    dispatchCartAction({ type: "REMOVE", id: id, amount:  amount});
  };

  const cartContext = {
    items: cartState.items,
    totalAmount: cartState.totalAmount,
    addItem: addItemToCartHandler,
    removeItem: removeItemFromCartHandler,
  };

  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
