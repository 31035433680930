import { Fragment } from "react";
import ReactDOM from 'react-dom'

import styles from "./Modal.module.css";

const BackDrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onClick}></div>;
};

const ModalOverlay = (props) => {
    return <div className={styles.modal}>
        <div className={styles.content}>{props.children}</div>
    </div>
};

const portalElement = document.getElementById('overlays')

const Modal = (props) => {
  return <Fragment>
    {ReactDOM.createPortal(<BackDrop onClick={props.onClick}/>, portalElement)}
    {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
  </Fragment>;
};

export default Modal;
