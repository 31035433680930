import { useRef } from "react";

import Input from "../../UI/input";
import styles from "./MealItemForm.module.css";

const MealItemForm = (props) => {

  const amountInputRef = useRef()

  const addSubmitHandler = event => {
    event.preventDefault()

    props.onSubmitAmount(Number(amountInputRef.current.value))

  }


  return (
    <form className={styles.form} onSubmit={addSubmitHandler}>
      <Input label="Amount" input={{
        ref: amountInputRef,
        id: 'amount',
        type: 'number',
        min: '1',
        max: props.max,
        step: '1',
        defaultValue: '1',
      }} />
      <button>+add</button>
    </form>
  );
};

export default MealItemForm;
