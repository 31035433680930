import { useContext, useState, useEffect } from "react";
import CartContext from "../../store/cart-context";

import CartIcon from "../Cart/CartIcon";

import styles from "./HeaderCartButton.module.css";

const HeaderCartButton = (props) => {
  const [cartHeight,setCartHeight] = useState(false)
  const cartCtx = useContext(CartContext);

  // const numberOfCartItem = cartCtx.items.length;

  const classes = `${styles.button} ${cartHeight && styles.bump}`

  const { items } = cartCtx

  const numberOfCartItem = cartCtx.items.reduce((currentNum, item) => {
      return currentNum + item.amount
  },0)

  useEffect(()=>{

    if ( items.length === 0 ) {
      return;
    }

    setCartHeight(true)

    const timer = setTimeout(() => {
      setCartHeight(false)
    },300)

    return () => {
      clearTimeout(timer)
    }

  },[items])

  return (
    <button
      className={classes}
      onClick={props.onClick}
    >
      <span className={styles.icon}>
        <CartIcon />
      </span>
      <span>Your Cart</span>
      <span className={styles.badge}>{numberOfCartItem}</span>
    </button>
  );
};

export default HeaderCartButton;
