import useInput from "../../hooks/use-input";

import styles from "./Checkout.module.css";

const Checkout = (props) => {
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHassError,
    valueChangeHandler: nameInputChangeHandler,
    valueBlurHandler: nameInputBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredPost,
    isValid: enteredPostIsValid,
    hasError: postInputHassError,
    valueChangeHandler: postInputChangeHandler,
    valueBlurHandler: postInputBlurHandler,
    reset: resetPost,
  } = useInput((value) => value.trim() !== "" && value.length === 5);

  const {
    value: enteredStreet,
    isValid: enteredStreetIsValid,
    hasError: streetInputHassError,
    valueChangeHandler: streetInputChangeHandler,
    valueBlurHandler: streetInputBlurHandler,
    reset: resetStreet,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredCity,
    isValid: enteredCityIsValid,
    hasError: cityInputHassError,
    valueChangeHandler: cityInputChangeHandler,
    valueBlurHandler: cityInputBlurHandler,
    reset: resetCity,
  } = useInput((value) => value.trim() !== "");

  const nameInputClasses = nameInputHassError ? `${styles.control} ${styles.invalid}` : styles.control
  const postInputClasses = postInputHassError ? `${styles.control} ${styles.invalid}` : styles.control
  const streetInputClasses = streetInputHassError ? `${styles.control} ${styles.invalid}` : styles.control
  const cityInputClasses = cityInputHassError ? `${styles.control} ${styles.invalid}` : styles.control

  let formIsValid = false 

  if ( enteredNameIsValid && enteredPostIsValid && enteredCityIsValid && enteredStreetIsValid ) {
    formIsValid = true
  }

  const confirmHandler = (event) => {
    event.preventDefault();

    if ( !enteredNameIsValid && !enteredPostIsValid && !enteredCityIsValid && !enteredStreetIsValid ) {
      return;
    }

    const data = {
      name: enteredName,
      postCode: enteredPost,
      city: enteredCity,
      street: enteredStreet,
    }

    props.onConfirm(data)

    resetName();
    resetPost();
    resetCity();
    resetStreet();
  };

  return (
    <form className={styles.form} onSubmit={confirmHandler}>
      <div className={nameInputClasses}>
        <label htmlFor="name">Your Name</label>
        <input
          type="text"
          id="name"
          value={enteredName}
          onChange={nameInputChangeHandler}
          onBlur={nameInputBlurHandler}
        />
        {nameInputHassError && <p>name most not be empty.</p>}
      </div>
      <div className={postInputClasses}>
        <label htmlFor="post">Your Postal Code</label>
        <input
          type="text"
          id="post"
          value={enteredPost}
          onChange={postInputChangeHandler}
          onBlur={postInputBlurHandler}
        />
        {postInputHassError && <p>name most be exactly five number.</p>}
      </div>
      <div className={streetInputClasses}>
        <label htmlFor="street">Your Street</label>
        <input
          type="text"
          id="street"
          value={enteredStreet}
          onChange={streetInputChangeHandler}
          onBlur={streetInputBlurHandler}
        />
        {streetInputHassError && <p>street most not be empty.</p>}
      </div>
      <div className={cityInputClasses}>
        <label htmlFor="city">Your City</label>
        <input
          type="text"
          id="city"
          value={enteredCity}
          onChange={cityInputChangeHandler}
          onBlur={cityInputBlurHandler}
        />
        {cityInputHassError && <p>city most not be empty.</p>}
      </div>
      <div className={styles.actions}>
        <button type="button" onClick={props.onCancel}>
          Cancel
        </button>
        <button className={styles.submit} disabled={!formIsValid}>Confirm</button>
      </div>
    </form>
  );
};

export default Checkout;
