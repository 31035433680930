import styles from "./CartItem.module.css";

const CartItem = (props) => {

    const addClickHandler = event => {
        props.onAddItem({
            id: props.id,
            name: props.name,
            amount: props.amount,
            price: props.price
        })
    }
    const removeClickHandler = event => {
        props.onRemoveItem(props.id)
    }

  return (
    <li className={styles["cart-item"]}>
      <div>
        <h2>{props.name}</h2>
        <div className={styles.summary}>
          <span className={styles.price}>{props.price}</span>
          <span className={styles.amount}>x {props.amount}</span>
        </div>
      </div>
      <div className={styles.actions}>
        <button onClick={removeClickHandler}>−</button>
        <button onClick={addClickHandler}>+</button>
      </div>
    </li>
  );
};

export default CartItem;
